<template>
  <div class="faq">
    <Header/>
    <div class="main">
      <div class="title">Website FAQ</div>
      <div class="content">
        <van-collapse v-model="activeNames">
          <van-collapse-item title="1. What is Meta-Utopia?" name="1">Meta-Utopia is a MetaFi project driven by community consensus to build an ideal world, an Utopia in the Metaverse. Cultivation of land and population growth sets the foundation of the Meta-Utopia before building and development of the island commence. The target of the Meta-Utopia is to realize a sustainable virtual ecosystem and create a peaceful environment for its 54 Cities and its citizens. </van-collapse-item>
          <van-collapse-item title="2. What can we do in Meta-Utopia?" name="2">Meta-Utopia is united by community members from all around the world. Everyone can join Meta-Utopia to learn and understand about the metaverse. Protophylarchs (City owners) are voted by each community to head the first season of population growth. During the second season, after the development of the Capital City, Amarout, each of the 54 cities will be developed in The SANDBOX metaverse. Bridging the connection between the real-world and the metaverse, real-life businesses start to integrate their activities between both universe. In the third season, the integration of MetaFi with fractionalization creates value for all users within the Meta-Utopia.</van-collapse-item>
          <van-collapse-item title="3. What can I achieve in Meta-Utopia?" name="3">Meta-Utopia is a global family. Everyone can be part of building this Utopia and be rewarded for their contribution.  Meta-Utopians can connect with each other across the globe with SocialFi, collect NFTs and play games with GameFi, learn and earn from DeFi and eventually be the leader, the influencer in your own circle when the entire industry takes off.</van-collapse-item>
          <van-collapse-item title="4. Why The SANDBOX Metaverse? " name="4">The SANDBOX is the top leading metaverse that is built on Ethereum, allowing a decentralized gaming experience for all its users and creators. Meta-Utopia promotes decentralization to all its citizens and The SANDBOX is the ideal platform for all Meta-Utopians where there can learn and earn and store their NFTs in their own wallets. </van-collapse-item>
          <van-collapse-item title="5. Who is in The SANDBOX?" name="5">
            <p>The SANDBOX currently houses over 300 international brands such as Gucci, Adidas, and have partnerships in both</p>
            <p>Public sector (Dubai’s VARA to Establish World’s First Metaverse HQ in The Sandbox :
              <a href="https://coincentral.com/dubais-vara-to-establish-worlds-first-metaverse-hq-in-the-sandbox">https://coincentral.com/dubais-vara-to-establish-worlds-first-metaverse-hq-in-the-sandbox</a>)</p>
            <p style="word-break: break-word">Private sector (HSBC Enters the Metaverse Through Partnership with The Sandbox :
              <a href="https://www.coindesk.com/business/2022/03/16/hsbc-enters-the-metaverse-through-partnership-with-the-sandbox">https://www.coindesk.com/business/2022/03/16/hsbc-enters-the-metaverse-through-partnership-with-the-sandbox</a>)</p>
          </van-collapse-item>
          <van-collapse-item title="6. What is MUA?" name="6">MUA stands for the Meta-Utopian Angels. MUA is a voluntary movement that assist the development of the community for Meta-Utopia. They are great at creating events, meetups and also helping to spread the message in their native languages.
            For more info to become an Angel, kindly contact <a href="https://t.me/OuYangQiQi">https://t.me/OuYangQiQi</a></van-collapse-item>
          <van-collapse-item title="7. What's $AMA token contract address?" name="7">
            <p>Contract Address:<br/>0xE9Cd2668FB580c96b035B6d081E5753f23FE7f46</p>
            <p>Dextools Link: <br/><a href="https://www.dextools.io/app/en/bnb/pair-explorer/0x63072ac448811f1dd2c75f1f39764501b26a1978">https://www.dextools.io/app/en/bnb/pair-explorer/<br/>0x63072ac448811f1dd2c75f1f39764501b26a1978</a></p>
            <p style="word-break: break-all;">PancakeSwap Link:<br/><a href="https://pancakeswap.finance/swap?outputCurrency=0xE9Cd2668FB580c96b035B6d081E5753f23FE7f46&inputCurrency=0x55d398326f99059fF775485246999027B3197955">https://pancakeswap.finance/swap?<br/>outputCurrency=0xE9Cd2668FB580c96b035B6d081E5753f23FE7f46&inputCurrency=<br/>0x55d398326f99059fF775485246999027B3197955</a></p>
          </van-collapse-item>
        </van-collapse>
      </div>
    </div>
    <Follow/>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Follow from "@/components/Follow";
import Footer from "@/components/Footer";

export default {
  name: "Faq",
  components: { Header, Follow, Footer },
  data() {
    return {
      activeNames: ['-1']
    }
  }
}
</script>

<style lang="less" scoped>
.faq{
  .main{
    margin-top: 49px;
    position: relative;
    color: #fff;
    background-color: #12191c;
    padding: 38px 28px 28px 28px;
    text-align: left;
    .title{
      font-family: Montserrat-Bold, Verdana;
      font-size: 16px;
      font-weight: bold;
      line-height: 23px;
      text-transform: uppercase;
      background-image: -webkit-linear-gradient(top, #0bedad, #0ba191);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 18px;
    }
    .content{
      /deep/ .van-cell{
        background-color: transparent;
        color: #C3C3C3;
        padding: 10px 0;
      }
      /deep/ .van-cell__title{
        font-family: Montserrat-Bold, Verdana;
        font-size: 16px;
        font-weight: bold;
        line-height: 23px;
        text-transform: uppercase;
        background-image: -webkit-linear-gradient(top, #0bedad, #0ba191);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      /deep/ .van-cell__right-icon{
        color: #0BEDAD;
      }
      /deep/ .van-collapse-item__content{
        background-color: transparent;
        padding: 0 16px 10px 0;
        color: #C3C3C3;
        font-family: Montserrat-Regular, Verdana;
        font-size: 12px;
        line-height: 20px;
        p{
          margin-bottom: 12px;
        }
        a{
          color: #0BF0DB;
        }
      }
      /deep/ .van-cell::after{
        border-bottom: none;
      }
      [class*=van-hairline]::after{
        border: none;
      }
      .van-collapse-item--border::after{
        border-top: none;
      }
    }
  }
}
</style>
